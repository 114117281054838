import { useMemo } from 'react';

export const BUNDLE_TAG_NAME = 'bundle';
export interface UseAvailabilityProps {
    availableForSale: boolean;
    quantityAvailable: number;
    tags: string[];
}
export function useAvailability({ availableForSale, quantityAvailable, tags }: UseAvailabilityProps): boolean {
    return useMemo(
        () => ((availableForSale && quantityAvailable > 0) || tags.includes(BUNDLE_TAG_NAME)) ?? false,
        [availableForSale, quantityAvailable, tags],
    );
}
